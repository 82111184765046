import React from 'react';

import style from './aiSearchAccordionHeader.module.scss';
const AiSearchAccordionHeader: React.FC<any> = ({ items }) => (
  <div>
    <div
      className={style.accordionHeader}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        items?.product_url && window.open(items?.product_url);
      }}>
      <div className={style.accordionHeaderImage}>
        {items.image ? (
          <img src={items?.image} alt="" className={style.headerImg} />
        ) : (
          <div className={style.noImageFound} />
        )}
      </div>
      <div className={style.accordionHeaderContent}>
        <h3 className={style.accordionHeaderContentTitle}>{items?.name || ''}</h3>
        <div className={style.accordionHeaderContentDescription}>{items['brand-name'] || ''}</div>
      </div>
    </div>
  </div>
);

export default AiSearchAccordionHeader;
