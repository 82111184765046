import { MODALTYPE } from '../../ProductDetail/modaltypes';

import style from './buttonContainer.module.scss';
interface ButtonContainerProps {
  Icon?: any;
  text?: string;
  MenuIcon?: any;
  openModal?: ((modalType: string) => void) | null;
  modalType: string;
  handlers?: any;
  projectHandlers?: (handlers?: any) => void | undefined;
  onClick?: () => void;
}
const ButtonContainer: React.FC<ButtonContainerProps> = ({
  Icon,
  text = 'label',
  MenuIcon,
  openModal,
  modalType,
  handlers,
  projectHandlers,
  onClick
}) => (
  <div
    data-testid={text}
    className={style.container}
    onClick={() => {
      if (onClick) {
        onClick();
      }

      if (handlers && projectHandlers && modalType === MODALTYPE.ADD_TO_PROJECT) {
        projectHandlers(handlers);
      }

      if (openModal) {
        openModal(modalType);
      }
    }}>
    <div className={style.buttonInfo}>
      <div className={style.iconContainer}>{Icon}</div>
      <h3 className={style.buttonLabel}>{text}</h3>
    </div>
    {MenuIcon}
  </div>
);

export default ButtonContainer;
