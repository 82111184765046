import React from 'react';

import style from './Breadcrumb.module.scss';

interface BreadcrumbProps {
  breadcrumbs: any[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => (
  <div className={style.breadcrumbContainer}>
    <div className={style.breadcrumbMain}>
      {!!breadcrumbs &&
        breadcrumbs.map((breadcrumb: any, index: number) => {
          const label = breadcrumb.values[0].label;
          const url = breadcrumb.values[0].url;

          return (
            <React.Fragment key={index}>
              <a href={url} className={style.breadcrumbItemLink}>
                {label}
              </a>
              {index < breadcrumbs.length - 1 && (
                <span className={style.breadcrumbSeparator}> / </span>
              )}
            </React.Fragment>
          );
        })}
    </div>
  </div>
);

export default Breadcrumb;
