import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AISearchTitleIcon } from '../../assets/svgs/aiSearchTitleIcon';
import useKeyPress from '../../hooks/useKeyPress';
import { AISearchContainer } from '../shared/Container/AISearchContainer';
import CustomButton from '../shared/CustomButton';
import { ModalComponent } from '../shared/Modal/Modal';

import AISummaryContainer from './AISearchModal/aisumaryContainer';
import ModalBody from './AISearchModal/modalBody';
import ModalFooter from './AISearchModal/modalFooter';
import ModalHeader from './AISearchModal/modalHeader';

import style from './aiSearch.module.scss';

const AISearch: React.FC<any> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [initialReponse, setInitialResponse] = useState([]);
  const [chatSessionId, setChatSessionId] = useState<string>('');
  const [productData, setProductData] = useState({ products: [], summary: '' } as any);
  const [question, setQuestion] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isProductListExist, setIsProductListExist] = useState(false);
  const [productCount, setproductCount] = useState<number | null>(null);
  const [aiLevelSummary, setAILevelSummary] = useState(productData?.summary || '');
  const [isApiCallDone, setisApiCallDone] = useState<boolean>(false);
  const openModal = (): void => {
    setIsModalOpen(true);
  };
  const closeModal = (): void => {
    setIsModalOpen(false);
    setQuestion('');
    setInitialResponse([]);
    setProductData([]);
    setLoading(false);
    setAILevelSummary('');
    setIsProductListExist(false);
    setisApiCallDone(false);
  };

  useKeyPress(closeModal, () => fetchProductIds(question));

  const fetchProduct = useCallback(
    async (chatSessionId: string, ids?: string[]): Promise<void> => {
      try {
        setisApiCallDone(false);

        if (!data) {
          console.error('Data is not available');

          return;
        }

        const response = await data.fetchProducts(chatSessionId, ids);

        if (response) {
          setProductData(response);
          setInitialResponse([]);
          setisApiCallDone(true);

          if (response?.products?.length === 0) {
            setIsProductListExist(true);
          }
        }
      } catch (error) {
        setIsProductListExist(true);
        setInitialResponse([]);
        setProductData([]);
        setisApiCallDone(true);
      }
    },
    [data]
  );
  const fetchProductIds = useCallback(
    async (question?: string): Promise<void> => {
      if (question) {
        setLoading(true);
        setIsProductListExist(false);
        setProductData([]);
        setInitialResponse([]);
        setproductCount(null);
        setisApiCallDone(false);

        try {
          const response = await data?.fetchProductIds(question);

          if (response && response?.type) {
            if (response.type === 'count') {
              setproductCount(response.count || 0);
            } else {
              setInitialResponse(response.products || []);
              setChatSessionId(response.chat_session_id);
            }
          }
        } catch (error) {
          setIsProductListExist(true);
          setInitialResponse([]);
          setProductData([]);
        } finally {
          setLoading(false);
          setisApiCallDone(true);
        }
      }
    },
    [data]
  );

  useEffect(() => {
    if (!!initialReponse.length) {
      fetchProduct(chatSessionId, initialReponse);
    }
  }, [fetchProduct, chatSessionId, initialReponse, initialReponse.length]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.body.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.body.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  const renderModalBody = useCallback(() => {
    if (!isModalOpen) {
      return null;
    }

    return (
      <ModalBody
        searchItem={question}
        setSearchItem={setQuestion}
        initialData={initialReponse || []}
        productData={productData || { products: [], summary: '' }}
        fetchProductIds={fetchProductIds}
        loading={loading}
        data={data}
        productListExist={isProductListExist}
        productCount={productCount}
        aiLevelSummary={aiLevelSummary || ''}
        setAILevelSummary={setAILevelSummary}
        isApiCallDone={isApiCallDone}
        setisApiCallDone={setisApiCallDone}
      />
    );
  }, [
    isModalOpen,
    question,
    initialReponse,
    productData,
    fetchProductIds,
    loading,
    data,
    isProductListExist,
    productCount,
    aiLevelSummary,
    isApiCallDone
  ]);
  const openModalOnValidRegWall = async (): Promise<void> => {
    if (data?.regWall) {
      const { regWall } = data;
      const response = await regWall();

      if (response) {
        openModal();
      }

      return;
    }
  };

  return (
    <AISearchContainer>
      <div className={style.aiSearchContainer}>
        <AISummaryContainer
          Icon={<AISearchTitleIcon strokeColor="black" />}
          title="IntelliBuild"
          description="Try IntelliBuild product finder to find products using natural language. Simply ask for what you need by category, certification, or brand and get product recommendations. Or search for a specific product by name."
          isAISemanticDescription={true}
        />
        <div className={style.aiSearchButtons} onClick={() => openModalOnValidRegWall()}>
          <CustomButton label="Find Products" className={style.aiSearchButtonsStyle} />
        </div>
      </div>
      {isModalOpen && (
        <ModalComponent
          ref={modalRef}
          isOpen={isModalOpen}
          setIsModalOpen={closeModal}
          showCrossIcon={false}
          modalHeader={<ModalHeader />}
          modalBody={renderModalBody()}
          modalFooter={<ModalFooter closeModal={closeModal} />}
        />
      )}
    </AISearchContainer>
  );
};

export default AISearch;
