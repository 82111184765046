import React from 'react';

import SantizedHTMLRawData from '../../../../utils/sanitizeHTML';

import style from './mainSummaryContainer.module.scss';
interface MainSummaryContainerProps {
  Icon?: any;
  description?: string;
  mainSummary?: boolean;
  productSummary?: boolean;
}

const MainSummaryContainer: React.FC<MainSummaryContainerProps> = ({
  Icon,
  description,
  mainSummary,
  productSummary
}) => {
  const aiSummaryDescription = mainSummary
    ? style.modalmainSummaryDescription
    : productSummary
      ? style.productSummaryDescription
      : undefined;

  return (
    <div className={style.mainSummaryContainer}>
      <div className={style.mainSummaryContainerIcon}>{Icon && Icon}</div>
      <div className={aiSummaryDescription}>
        <SantizedHTMLRawData content={description || ''} />
      </div>
    </div>
  );
};

export default MainSummaryContainer;
