export const WaterDropIcon = ({ greenTags = false }: { greenTags?: boolean }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={greenTags ? '24' : '32'}
    height={greenTags ? '24' : '32'}
    viewBox="0 0 32 32"
    fill="none">
    <g clipPath="url(#clip0_2751_241)">
      <path
        d="M26 18C26 9 16 2 16 2C16 2 6 9 6 18C6 20.6522 7.05357 23.1957 8.92893 25.0711C10.8043 26.9464 13.3478 28 16 28C18.6522 28 21.1957 26.9464 23.0711 25.0711C24.9464 23.1957 26 20.6522 26 18Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 24C19.5 23.5788 21.5762 21.5 22 19"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2751_241">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
