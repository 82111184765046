export const ThumbsDownIcon = (): JSX.Element => (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_254_733)">
      <path
        d="M3.5 5.75H8.75V17.125H3.5C3.26794 17.125 3.04538 17.0328 2.88128 16.8687C2.71719 16.7046 2.625 16.4821 2.625 16.25V6.625C2.625 6.39294 2.71719 6.17038 2.88128 6.00628C3.04538 5.84219 3.26794 5.75 3.5 5.75Z"
        stroke="black"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 17.125L13.125 25.875C14.0533 25.875 14.9435 25.5063 15.5999 24.8499C16.2563 24.1935 16.625 23.3033 16.625 22.375V19.75H23.625C23.8732 19.7499 24.1186 19.697 24.3449 19.5949C24.5711 19.4927 24.773 19.3435 24.9372 19.1573C25.1013 18.9711 25.224 18.7521 25.2971 18.5149C25.3701 18.2776 25.3918 18.0275 25.3608 17.7812L24.0483 7.28125C23.995 6.85843 23.7893 6.46959 23.4697 6.18766C23.1501 5.90574 22.7387 5.75012 22.3125 5.75H8.75"
        stroke="black"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_254_733">
        <rect width="28" height="28" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
