import React from 'react';

const LinkIcon: React.FC = () => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.36838 4.28125H2.75C2.20299 4.28125 1.67839 4.49855 1.29159 4.88534C0.904798 5.27214 0.6875 5.79674 0.6875 6.34375C0.6875 6.89076 0.904798 7.41536 1.29159 7.80216C1.67839 8.18895 2.20299 8.40625 2.75 8.40625H4.8125C5.14255 8.40642 5.46782 8.32738 5.76099 8.17578C6.05416 8.02417 6.30667 7.80441 6.49729 7.53498C6.68792 7.26554 6.8111 6.95429 6.85649 6.62738C6.90187 6.30046 6.86814 5.96743 6.75812 5.65625H6.1875C6.12838 5.65625 6.07062 5.66312 6.01562 5.67756C6.13157 5.88691 6.19087 6.12289 6.18766 6.36218C6.18445 6.60148 6.11885 6.83578 5.99733 7.04195C5.87581 7.24811 5.70259 7.41899 5.49479 7.53769C5.28699 7.65639 5.05181 7.7188 4.8125 7.71875H2.75C2.38533 7.71875 2.03559 7.57388 1.77773 7.31602C1.51987 7.05816 1.375 6.70842 1.375 6.34375C1.375 5.97908 1.51987 5.62934 1.77773 5.37148C2.03559 5.11362 2.38533 4.96875 2.75 4.96875H3.80531C3.95519 4.71025 4.14563 4.47787 4.36906 4.28125H4.36838Z"
      fill="#F9F9F9"
    />
    <path
      d="M4.65027 4.9687H4.81252C5.06277 4.9687 5.29789 5.03538 5.50002 5.15295C5.70892 5.03195 5.94611 4.96838 6.18752 4.9687H6.34977C6.11889 4.7102 5.82666 4.51396 5.50002 4.39807C5.17332 4.51386 4.88107 4.71011 4.65027 4.9687Z"
      fill="#F9F9F9"
    />
    <path
      d="M6.18748 4.28125C5.85743 4.28108 5.53216 4.36012 5.23899 4.51173C4.94582 4.66333 4.69332 4.88309 4.50269 5.15252C4.31206 5.42196 4.18888 5.73321 4.1435 6.06012C4.09811 6.38704 4.13184 6.72007 4.24186 7.03125H4.99673C4.87605 6.82223 4.81252 6.58512 4.81252 6.34376C4.81252 6.1024 4.87605 5.86529 4.99672 5.65627C5.1174 5.44724 5.29097 5.27366 5.5 5.15298C5.70902 5.03229 5.94612 4.96876 6.18748 4.96875H8.24998C8.61466 4.96875 8.96439 5.11362 9.22226 5.37148C9.48012 5.62934 9.62498 5.97908 9.62498 6.34375C9.62498 6.70842 9.48012 7.05816 9.22226 7.31602C8.96439 7.57388 8.61466 7.71875 8.24998 7.71875H7.19467C7.04509 7.97684 6.85471 8.20901 6.63092 8.40625H8.24998C8.52083 8.40625 8.78903 8.3529 9.03927 8.24925C9.2895 8.1456 9.51687 7.99368 9.70839 7.80216C9.89991 7.61064 10.0518 7.38327 10.1555 7.13303C10.2591 6.8828 10.3125 6.6146 10.3125 6.34375C10.3125 6.0729 10.2591 5.8047 10.1555 5.55447C10.0518 5.30423 9.89991 5.07686 9.70839 4.88534C9.51687 4.69382 9.2895 4.5419 9.03927 4.43825C8.78903 4.3346 8.52083 4.28125 8.24998 4.28125H6.18748Z"
      fill="#F9F9F9"
    />
    <path
      d="M5.50002 8.28942C5.82658 8.17337 6.11877 7.97716 6.34977 7.7188H6.18752C5.94611 7.71911 5.70892 7.65554 5.50002 7.53455C5.29112 7.65554 5.05393 7.71911 4.81252 7.7188H4.65027C4.87852 7.97386 5.17002 8.17255 5.50002 8.28942Z"
      fill="#F9F9F9"
    />
  </svg>
);

export default LinkIcon;
