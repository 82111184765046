import { useState } from 'react';

import TopArrow from '../../../assets/svgs/topArrow';

import style from './accordion.module.scss';

const Accordion = ({
  headerContent,
  isContributionProject = false,
  children,
  isEvaluation = false,
  isProductEvluation,
  evaluation = false
}: {
  headerContent: any;
  isContributionProject?: boolean;
  children: React.ReactElement;
  isEvaluation?: boolean;
  isProductEvluation?: boolean;
  evaluation?: boolean;
}): JSX.Element => {
  const [isActive, setIsActive] = useState(true);
  const onHeaderClick = (): void => {
    setIsActive(!isActive);
  };

  return (
    <div
      className={`${style.accordion} ${isProductEvluation || evaluation ? style.productEvalAccordion : ''}`}>
      <div
        className={`${
          !isContributionProject
            ? style.accordionNonContibutionHeader
            : style.accordionContributionHeader
        }
            ${isEvaluation ? style.evaluationHeader : ''}`}
        {...(!isContributionProject && { onClick: onHeaderClick })}>
        {headerContent}
        {!isEvaluation && (
          <div className={style.headerIcon} style={{ cursor: 'pointer' }}>
            <TopArrow
              isContributionProject={isContributionProject}
              className={`${isActive ? `${style.rotateZero}` : `${style.rotate360}`} pdfAccordionIconInvisible`}
            />
          </div>
        )}
      </div>
      <div
        data-testid="accordion-content"
        className={`${
          isActive
            ? `${style.contentShow} ${style.transition}`
            : `${style.contentHide} ${style.transition}`
        } pdfContentShow`}>
        {children}
      </div>
      {isContributionProject || isProductEvluation ? (
        <div onClick={onHeaderClick} className={`${style.accordionFooter} pdfContentHide`}>
          {isActive ? 'SHOW LESS ' : 'SHOW MORE'}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Accordion;
