import TopArrow from '../../../assets/svgs/topArrow';

import style from './productContributionGridHeader.module.scss';

const ProductContributionGridHeader = ({
  lensDetails = [],
  name,
  image,
  notes,
  productPage = false,
  onHeaderClick,
  index = 0,
  accordionContentShow,
  icon
}: {
  lensDetails?: any[];
  name?: string;
  image?: string;
  notes?: string;
  productPage?: boolean;
  onHeaderClick?: any;
  index?: number | undefined;
  accordionContentShow?: any;
  icon?: any;
}): JSX.Element => (
  <div>
    <div
      className={
        lensDetails.length
          ? style.productContributionGridHeader
          : `${style.productContributionHeaderBorder} ${style.productContributionGridHeader}`
      }>
      <div
        className={
          productPage ? style.productPageContributionHeader : style.productContributionGridTitle
        }>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {image && (
            <div className={style.productContributionIcon}>
              <img src={image} alt="accordian-header-img" />
            </div>
          )}
          {icon && icon}
          {name && <div>{name}</div>}
        </div>
        {productPage && (
          <div onClick={() => onHeaderClick(index)} style={{ cursor: 'pointer' }}>
            <TopArrow
              className={`${accordionContentShow[index]?.value ? `${style.rotateZero}` : `${style.rotate360}`} pdfAccordionIconInvisible`}
            />
          </div>
        )}
      </div>
      {notes && <div className={style.productContributionGridMessage}>{notes}</div>}
    </div>
  </div>
);

export default ProductContributionGridHeader;
