/* eslint-disable react/jsx-no-target-blank */
import ShareIcon from '../../../assets/svgs/sharableIcon';
import { filterArrayNullValues } from '../../../utils/filterArrayNullValues';
import { convertFractionToPercentage, isBooleanValue, isPercentValue } from '../../../utils/util';
import { ManufacturingLocation } from '../ManufacturingLocation/ManufacturingLocation';
import ToolTip from '../Tooltip/Tooltip';

import styles from './accordionRow.module.scss';

const PERCENT_TYPE = 'percent';

export function checkPdfLink({
  url,
  details,
  trackFn,
  type,
  brand,
  sku,
  item
}: {
  url: string;
  details?: any;
  trackFn?: any;
  type?: string;
  brand?: string;
  sku?: string;
  item?: any;
}): void {
  const regex = /\.pdf(\?[^\s]*)?(\#[^\s]*)?$/;
  const certificateType = regex.test(url) ? 'certificate.document' : 'certificate.certifier';
  let certificateIdent = null;

  if (item) {
    if (item?.id) {
      certificateIdent = item?.id;
    }
  }

  trackFn &&
    trackFn('links/go', {
      from: window.location.href,
      to: url,
      kind: type ? type : certificateType,
      attribute: details?.attribute,
      brand,
      sku,
      ...(certificateIdent && { 'cert-ident': certificateIdent })
    });
}

const renderMap = {
  LEVEL: (item: any) =>
    item?.kind ? (typeof item.kind === 'number' ? `LEVEL ${item.kind}` : item.kind) : null,
  ID_AND_KIND: (item: any) => (item?.kind && item?.id && !item?.version ? `(${item.id})` : null),
  ID_AND_VERSION: (item: any) =>
    !item?.kind && item?.id && item?.version ? `${item.id} (version ${item.version})` : null,
  KIND_AND_VERSION: (item: any) =>
    item?.kind && !item?.id && item?.version ? `(version ${item.version})` : null,
  ID_ONLY: (item: any) => (item.id && !item?.kind && !item?.version ? `${item.id}` : null),
  VERSION_ONLY: (item: any) =>
    item.version && !item?.id && !item?.kind ? `version ${item.version}` : null,
  ID_VERSION_AND_KIND: (item: any) =>
    item?.id && item?.kind && item?.version ? `(${item.id}, version ${item.version})` : null
};

export const RenderCerticateValues = ({
  value,
  renderUrlValue,
  regWall,
  isSignedIn,
  trackFn,
  details = {},
  product
}: {
  value: any;
  renderUrlValue?: any;
  regWall?: () => Promise<any> | null;
  isSignedIn?: boolean;
  trackFn?: any;
  details?: any;
  product?: any;
}): JSX.Element => (
  <div>
    {!renderUrlValue
      ? value?.map((item: any, index: number) => {
          const Tag = item?.url ? 'a' : item?.source ? 'a' : 'p';

          return (
            <div className={styles.accordionRowCertificateValue} key={`${item}-${index}`}>
              {isSignedIn ? (
                <Tag
                  {...(Tag === 'a' ? { href: item?.url, target: '_blank' } : {})}
                  onClick={() => {
                    if (Tag !== 'a') {
                      return;
                    }

                    if (Tag === 'a') {
                      checkPdfLink({
                        url: item?.url,
                        details: details,
                        trackFn: trackFn,
                        brand: product?.['brand-ident'] || '',
                        sku: product?.sku || '',
                        item
                      });
                    }
                  }}
                  className={`${Tag === 'a' && styles.accordionRowCertificateValueSpecifierLink}`}>
                  {renderMap['LEVEL'](item)}{' '}
                  {renderMap['ID_AND_KIND'](item) ||
                    renderMap['ID_AND_VERSION'](item) ||
                    renderMap['KIND_AND_VERSION'](item) ||
                    renderMap['ID_AND_VERSION'](item) ||
                    renderMap['ID_ONLY'](item) ||
                    renderMap['VERSION_ONLY'](item) ||
                    renderMap['ID_VERSION_AND_KIND'](item)}
                </Tag>
              ) : (
                <Tag
                  {...(isSignedIn ? { href: item?.url, target: '_blank' } : {})}
                  className={`${Tag === 'a' ? styles.accordionRowCertificateValueSpecifierLink : styles.accordionRowCertificateValueSpecifierP}`}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (regWall) {
                      if (Tag !== 'a') {
                        return;
                      }

                      const regWallValue = await regWall();

                      if (regWallValue) {
                        if (Tag === 'a') {
                          checkPdfLink({
                            url: item?.url,
                            details: details,
                            trackFn: trackFn,
                            brand: product?.['brand-ident'] || '',
                            sku: product?.sku || '',
                            item
                          });
                        }

                        window.open(item?.url, '_blank');
                      }
                    }
                  }}>
                  <p>{renderMap['LEVEL'](item)}</p>
                  <p>
                    {renderMap['ID_AND_KIND'](item) ||
                      renderMap['ID_AND_VERSION'](item) ||
                      renderMap['KIND_AND_VERSION'](item) ||
                      renderMap['ID_AND_VERSION'](item) ||
                      renderMap['ID_ONLY'](item) ||
                      renderMap['VERSION_ONLY'](item) ||
                      renderMap['ID_VERSION_AND_KIND'](item)}
                  </p>
                </Tag>
              )}
              {item?.product_type && <p>{`product type : ${item.product_type}`}</p>}
              {item?.country && <p>{`Country : ${item.country}`}</p>}
              {item?.standard_number && <p>{item.standard_number}</p>}
              {item?.certifier && (
                <div className={styles.accordionRowCertificateValueSpecifier}>
                  <p>{`Certified by`}</p>
                  {isSignedIn ? (
                    <Tag
                      onClick={() => {
                        if (Tag === 'a') {
                          checkPdfLink({
                            url: item?.source,
                            details: details,
                            trackFn: trackFn,
                            brand: product?.['brand-ident'] || '',
                            sku: product?.sku || '',
                            item
                          });
                        }
                      }}
                      className={`${Tag === 'a' ? styles.accordionRowCertificateValueSpecifierLink : styles.accordionRowCertificateValueSpecifierP}`}
                      {...(Tag === 'a' || isSignedIn
                        ? { href: item?.source, target: '_blank' }
                        : {})}>
                      {item.certifier}
                    </Tag>
                  ) : item?.source ? (
                    <Tag
                      {...(isSignedIn ? { href: item?.source, target: '_blank' } : {})}
                      className={styles.accordionRowCertificateValueSpecifierLink}
                      onClick={async (e) => {
                        if (regWall) {
                          e.preventDefault();
                          const regWallValue = await regWall();

                          if (regWallValue) {
                            checkPdfLink({
                              url: item?.source,
                              details: details,
                              trackFn: trackFn,
                              brand: product?.['brand-ident'] || '',
                              sku: product?.sku || '',
                              item
                            });
                            // window.location.href = item?.source;
                            window.open(item?.source, '_blank');
                          }
                        }
                      }}>
                      {item.certifier}
                    </Tag>
                  ) : (
                    <p>{item.certifier}</p>
                  )}
                </div>
              )}
              {item?.third_party_verified && '3rd Party Verified'}
              {item?.starts && !item.ends ? (
                <div className={styles.accordionRowCertificateValueSpecifier}>
                  <p>{`certified date `}</p>
                  <p>{`${item?.starts.split('T')[0]}`}</p>
                </div>
              ) : item?.starts && item?.ends ? (
                <div className={styles.accordionRowCertificateValueSpecifier}>
                  <p>{`valid`}</p>
                  <p>{`${item?.starts.split('T')[0]} to ${item?.ends.split('T')[0]}`}</p>
                </div>
              ) : (
                item?.ends && (
                  <div className={styles.accordionRowCertificateValueSpecifier}>
                    <p>{`valid until ${item?.ends.split('T')[0]}`}</p>
                  </div>
                )
              )}
              <div className={styles.materialType}>
                {item?.cc_material_health && (
                  <p>{`Material Health :${item?.cc_material_health || ''}`}</p>
                )}
                {item.cc_material_reutilization && (
                  <p>{`Material Reutilization :${item?.cc_material_reutilization || ''}`}</p>
                )}

                {item?.cc_renewable_energy_and_carbon_management && (
                  <p>
                    {`Renewable Energy & Carbon Management :
                 ${item?.cc_renewable_energy_and_carbon_management || ''}`}
                  </p>
                )}
                {item?.cc_social_fairness && (
                  <p>{`Social Fairness :${item?.cc_social_fairness || ''}`}</p>
                )}
                {item?.cc_water_stewardship && (
                  <p>{`Water Stewardship :${item?.cc_water_stewardship || ''}`}</p>
                )}
              </div>
            </div>
          );
        })
      : value?.map((item: any, index: number) => (
          <span key={`${item}-${index}`}>
            {isSignedIn ? (
              <a
                onClick={() => {
                  checkPdfLink({
                    url: item?.url,
                    details: details,
                    trackFn: trackFn,
                    type: 'certificate.document',
                    brand: product?.['brand-ident'] || '',
                    sku: product?.sku || '',
                    item
                  });
                }}
                key={index}
                {...(isSignedIn ? { href: item?.url, target: '_blank' } : {})}
                target="_blank"
                className={styles.accordionRowCertificateValueSpecifier}>
                {'Document'}
              </a>
            ) : (
              <a
                key={index}
                {...(isSignedIn ? { href: item?.url, target: '_blank' } : {})}
                onClick={async (e) => {
                  if (regWall) {
                    e.preventDefault();
                    const regWallValue = await regWall();

                    if (regWallValue) {
                      checkPdfLink({
                        url: item?.url,
                        details: details,
                        trackFn: trackFn,
                        type: 'certificate.document',
                        brand: product?.['brand-ident'] || '',
                        sku: product?.sku || '',
                        item
                      });

                      // window.location.href = item?.url;
                      window.open(item?.url, '_blank');
                    }
                  }
                }}
                className={styles.accordionRowCertificateValueSpecifierLink}>
                {'Document'}
              </a>
            )}
          </span>
        ))}
  </div>
);

export const RenderValue = ({
  value,
  type = 'string',
  tooltipText,
  isLensData = false,
  customAccordionRow = false,
  regWall,
  isSignedIn,
  trackFn,
  detailsData,
  product
}: {
  value: any;
  type?: string;
  tooltipText?: string;
  isToolTip?: boolean;
  isLensData?: boolean;
  customAccordionRow?: boolean;
  regWall?: () => Promise<any> | null;
  isSignedIn?: boolean;
  trackFn?: any;
  detailsData?: any;
  product?: any;
}): JSX.Element => {
  const manufacturingPlant = 'manufacturing-plant';
  const renderers: { [key: string]: () => JSX.Element | string } = {
    number: () => value,
    bool: () => isBooleanValue(value),
    string: () => (Array.isArray(value) ? value.join(', ') : value),
    url: () => {
      if (isSignedIn) {
        return (
          <a
            onClick={() => {
              checkPdfLink({
                url: value,
                details: detailsData,
                trackFn,
                type: 'url',
                brand: product?.['brand-ident'] || '',
                sku: product?.sku || ''
              });
            }}
            className={styles.iconLink}
            href={value}
            target="_blank">
            <ShareIcon /> External Link
          </a>
        );
      } else {
        return (
          <a
            {...(isSignedIn ? { href: value } : {})}
            className={styles.iconLink}
            onClick={async (e) => {
              e.preventDefault();

              if (regWall) {
                const regWallValue = await regWall();

                if (regWallValue) {
                  checkPdfLink({
                    url: value,
                    details: detailsData,
                    trackFn,
                    type: 'url',
                    brand: product?.['brand-ident'] || '',
                    sku: product?.sku || ''
                  });

                  window.open(value, '_blank');
                }
              }
            }}>
            <ShareIcon />
            <p className={styles.externalLink}>{`External Link`}</p>
          </a>
        );
      }
    },
    certificate: () => {
      let renderedComponent: React.ReactNode = '_';

      if (typeof value === 'object' && Array.isArray(value.value)) {
        renderedComponent = (
          <RenderCerticateValues
            value={value.value}
            regWall={regWall}
            isSignedIn={isSignedIn}
            trackFn={trackFn}
            details={detailsData}
            product={product}
          />
        );
      } else if (
        Array.isArray(value) &&
        value.length === 1 &&
        Object.keys(value[0]).length === 1 &&
        value[0].hasOwnProperty('url')
      ) {
        renderedComponent = (
          <RenderCerticateValues
            value={value}
            renderUrlValue
            regWall={regWall}
            isSignedIn={isSignedIn}
            trackFn={trackFn}
            details={detailsData}
            product={product}
          />
        );
      } else if (Array.isArray(value)) {
        renderedComponent = (
          <RenderCerticateValues
            value={value}
            regWall={regWall}
            isSignedIn={isSignedIn}
            details={detailsData}
            trackFn={trackFn}
            product={product}
          />
        );
      }

      return <div>{renderedComponent}</div>;
    },

    csi: () => (Array.isArray(value) ? value.join(', ') : value),
    [manufacturingPlant]: () =>
      value === null || value === '' || filterArrayNullValues(value).length
        ? '-'
        : !filterArrayNullValues(value).length && Array.isArray(value)
          ? value?.map((val, index) => (
              <div key={`${val}-${index}`} className={styles.manufacturingPlant}>
                <p>{val.city}</p>
                {(val?.post_rc || val?.pre_rc === 0) && (
                  <p>Post-consumer: {convertFractionToPercentage(val.post_rc)}</p>
                )}
                {(val?.pre_rc || val?.pre_rc === 0) && (
                  <p>Pre-consumer: {convertFractionToPercentage(val.pre_rc)}</p>
                )}
              </div>
            ))
          : value,
    percent: () => isPercentValue(value),
    date: () => value && value?.split('T')[0]
  };

  if (value === null || value === undefined || value === '') {
    return <div className={styles.accordionRowValue}>-</div>;
  }

  const renderValue = renderers[type || 'string'];

  return (
    <div
      key={Math.random().toString()}
      className={
        isLensData
          ? styles.accordionRowValue
          : customAccordionRow
            ? styles.customRowWalue
            : styles.accordionProductContributionValue
      }>
      {tooltipText ? <ToolTip tooltipText={tooltipText}>{renderValue()}</ToolTip> : renderValue()}
    </div>
  );
};

const AccordionRow = ({
  detailsData,
  title,
  value,
  type,
  creditName,
  tooltipText,
  city,
  postRc,
  preRc,
  state,
  zipcode,
  isManufactureLocation,
  isLensData,
  isLightContent,
  index,
  dataLength,
  isCreditDetails,
  nestedRow = false,
  customAccordionRow = false,
  regWall,
  isSignedIn,
  trackFn,
  product
}: {
  title?: string;
  value?: any;
  type?: string;
  creditName?: string;
  tooltipText?: string;
  city?: string;
  postRc?: number;
  preRc?: number;
  state?: string;
  zipcode?: string;
  locationIndex?: number;
  isManufactureLocation?: boolean;
  isLensData?: boolean;
  isLightContent?: boolean;
  index?: number;
  dataLength?: number | undefined;
  isCreditDetails?: boolean;
  nestedRow?: boolean;
  customAccordionRow?: boolean;
  regWall?: () => Promise<any> | null;
  isSignedIn?: boolean;
  trackFn?: any;
  detailsData?: any;
  product?: any;
}): JSX.Element => {
  const locationProps = {
    city,
    postRc,
    preRc,
    state,
    zipcode,
    index,
    dataLength
  };

  return (
    <div>
      {!isManufactureLocation ? (
        <div
          className={`${(dataLength && index === dataLength - 1) || nestedRow ? styles.row : styles.accordionRow} ${isCreditDetails ? styles.creditDetails : customAccordionRow ? styles.customAccordionRow : ''}`}>
          {title || creditName ? (
            <div
              className={`${isLensData ? styles.accordionRowBoldTitle : styles.accordionProductContributionTitle}
            ${isLightContent ? styles.accordionRowLightContent : styles.accordionRowBoldTitle}`}>
              {detailsData?.description ? (
                <ToolTip isLeftTooltip={true} tooltipText={detailsData?.description}>
                  {title} {creditName && `(${creditName})`}
                </ToolTip>
              ) : (
                `${title}${creditName ? ` (${creditName})` : ''}`
              )}
            </div>
          ) : (
            ''
          )}
          {(value || type === PERCENT_TYPE) && (
            <RenderValue
              value={type === PERCENT_TYPE ? (value === '' ? 0 : value) : value}
              type={type}
              tooltipText={tooltipText}
              isLensData={isLensData}
              customAccordionRow={customAccordionRow}
              regWall={regWall}
              trackFn={trackFn}
              isSignedIn={isSignedIn}
              detailsData={detailsData}
              product={product}
            />
          )}
        </div>
      ) : (
        <ManufacturingLocation {...locationProps} />
      )}
    </div>
  );
};

export default AccordionRow;
