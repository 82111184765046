import React from 'react';

const SearchIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4999 17.5L13.8749 13.875"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83314 10.3542L5.32897 9.43149C5.25993 9.406 5.20035 9.35996 5.15827 9.29957C5.11619 9.23919 5.09363 9.16735 5.09363 9.09375C5.09363 9.02015 5.11619 8.94831 5.15827 8.88793C5.20035 8.82754 5.25993 8.7815 5.32897 8.75601L7.83314 7.83326L8.75589 5.3291C8.78138 5.26005 8.82742 5.20047 8.8878 5.15839C8.94819 5.11631 9.02003 5.09375 9.09363 5.09375C9.16723 5.09375 9.23906 5.11631 9.29945 5.15839C9.35984 5.20047 9.40588 5.26005 9.43136 5.3291L10.3541 7.83326L12.8583 8.75601C12.9273 8.7815 12.9869 8.82754 13.029 8.88793C13.0711 8.94831 13.0936 9.02015 13.0936 9.09375C13.0936 9.16735 13.0711 9.23919 13.029 9.29957C12.9869 9.35996 12.9273 9.406 12.8583 9.43149L10.3541 10.3542L9.43136 12.8584C9.40588 12.9275 9.35984 12.987 9.29945 13.0291C9.23906 13.0712 9.16723 13.0938 9.09363 13.0938C9.02003 13.0938 8.94819 13.0712 8.8878 13.0291C8.82742 12.987 8.78138 12.9275 8.75589 12.8584L7.83314 10.3542Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;
