import React from 'react';

const UserAvatar: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6484 19.8736C20.2206 17.4052 18.0203 15.6352 15.4525 14.7961C16.7226 14.04 17.7094 12.8878 18.2614 11.5166C18.8134 10.1453 18.8999 8.63077 18.5078 7.20554C18.1157 5.78031 17.2666 4.5232 16.0909 3.62726C14.9151 2.73132 13.4778 2.24609 11.9996 2.24609C10.5215 2.24609 9.08414 2.73132 7.90842 3.62726C6.73269 4.5232 5.88358 5.78031 5.49146 7.20554C5.09935 8.63077 5.18592 10.1453 5.73788 11.5166C6.28984 12.8878 7.27668 14.04 8.54683 14.7961C5.97902 15.6343 3.77871 17.4043 2.35089 19.8736C2.29853 19.959 2.2638 20.054 2.24875 20.153C2.2337 20.2521 2.23863 20.3531 2.26326 20.4502C2.28789 20.5472 2.33171 20.6384 2.39214 20.7183C2.45257 20.7981 2.52838 20.8651 2.6151 20.9152C2.70183 20.9653 2.79771 20.9975 2.89709 21.0099C2.99647 21.0224 3.09733 21.0148 3.19373 20.9876C3.29012 20.9604 3.3801 20.9142 3.45835 20.8517C3.5366 20.7892 3.60154 20.7117 3.64933 20.6236C5.41558 17.5711 8.53746 15.7486 11.9996 15.7486C15.4618 15.7486 18.5837 17.5711 20.35 20.6236C20.3977 20.7117 20.4627 20.7892 20.5409 20.8517C20.6192 20.9142 20.7092 20.9604 20.8056 20.9876C20.902 21.0148 21.0028 21.0224 21.1022 21.0099C21.2016 20.9975 21.2975 20.9653 21.3842 20.9152C21.4709 20.8651 21.5467 20.7981 21.6072 20.7183C21.6676 20.6384 21.7114 20.5472 21.736 20.4502C21.7607 20.3531 21.7656 20.2521 21.7505 20.153C21.7355 20.054 21.7008 19.959 21.6484 19.8736ZM6.74964 8.99864C6.74964 7.96029 7.05755 6.94526 7.63443 6.0819C8.21131 5.21854 9.03124 4.54564 9.99056 4.14828C10.9499 3.75092 12.0055 3.64695 13.0239 3.84952C14.0423 4.05209 14.9777 4.55211 15.712 5.28633C16.4462 6.02056 16.9462 6.95602 17.1488 7.97442C17.3513 8.99282 17.2474 10.0484 16.85 11.0077C16.4527 11.967 15.7797 12.787 14.9164 13.3639C14.053 13.9407 13.038 14.2486 11.9996 14.2486C10.6077 14.2472 9.27322 13.6936 8.28898 12.7093C7.30473 11.7251 6.75113 10.3906 6.74964 8.99864Z"
      fill="black"
    />
  </svg>
);

export default UserAvatar;
