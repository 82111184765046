import React from 'react';

interface AISearchTitleIconProps {
  strokeColor?: string;
  aiSummary?: boolean;
}
export const AISearchTitleIcon: React.FC<AISearchTitleIconProps> = ({ strokeColor, aiSummary }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={aiSummary ? '18' : '24'}
    height={aiSummary ? '18' : '24'}
    viewBox="0 0 24 24"
    fill="none">
    <g clipPath="url(#clip0_69_2227)">
      <path
        d="M7.90032 16.0994L2.73563 14.1963C2.59322 14.1438 2.47035 14.0488 2.38356 13.9243C2.29677 13.7997 2.25024 13.6516 2.25024 13.4998C2.25024 13.348 2.29677 13.1998 2.38356 13.0753C2.47035 12.9507 2.59322 12.8558 2.73563 12.8032L7.90032 10.9001L9.80344 5.73539C9.85601 5.59298 9.95096 5.4701 10.0755 5.38332C10.2001 5.29653 10.3482 5.25 10.5 5.25C10.6518 5.25 10.8 5.29653 10.9245 5.38332C11.0491 5.4701 11.144 5.59298 11.1966 5.73539L13.0997 10.9001L18.2644 12.8032C18.4068 12.8558 18.5297 12.9507 18.6165 13.0753C18.7032 13.1998 18.7498 13.348 18.7498 13.4998C18.7498 13.6516 18.7032 13.7997 18.6165 13.9243C18.5297 14.0488 18.4068 14.1438 18.2644 14.1963L13.0997 16.0994L11.1966 21.2641C11.144 21.4065 11.0491 21.5294 10.9245 21.6162C10.8 21.703 10.6518 21.7495 10.5 21.7495C10.3482 21.7495 10.2001 21.703 10.0755 21.6162C9.95096 21.5294 9.85601 21.4065 9.80344 21.2641L7.90032 16.0994Z"
        stroke={strokeColor ? strokeColor : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 1.5V6"
        stroke={strokeColor ? strokeColor : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6.75V9.75"
        stroke={strokeColor ? strokeColor : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 3.75H18.75"
        stroke={strokeColor ? strokeColor : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 8.25H22.5"
        stroke={strokeColor ? strokeColor : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_69_2227">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
