import { components } from 'react-select';

import { PlusIcon } from '../../../assets/svgs/plusIcon';

import style from './customMenu.module.scss';

interface CustomMenuProps {
  setshowFields?: any;
  setSelectedOption?: (option: any) => void;
  setMenuIsOpen: (value: boolean | ((prevState: boolean) => boolean)) => void;
}

export const CustomMenu: React.FC<CustomMenuProps & any> = (props) => (
  <components.Menu {...props}>
    <div>
      {props.selectProps.fetchingData ? (
        <span className="fetching">Fetching data...</span>
      ) : (
        <div>{props.children}</div>
      )}
      <div
        style={{ marginTop: '-0.3rem' }}
        className={style.customMenuContainer}
        onClick={() => {
          if (props?.setMenuIsOpen) {
            props?.setMenuIsOpen(false);
          }

          if (props?.setSelectedOption) {
            props?.setSelectedOption({
              value: 'Create new project',
              label: 'Create new project'
            });
          }

          props?.setshowFields({
            addProject: false
          });
        }}>
        <p>Create new project</p>
        <PlusIcon />
      </div>
    </div>
  </components.Menu>
);
