import Rating from '../../../../assets/svgs/rating';
import { Container } from '../../../shared/Container';
import CustomButton from '../../../shared/CustomButton';
import IconBox from '../../../shared/IconBox/iconBox';

import style from './ContactUs.module.scss';

type ContactUsProps = {
  trackFn?: (arg: string) => void;
  openModal: (arg: string) => void;
};

const logoUrls: { [key: string]: string } = {
  mindfulMaterials:
    'https://ecomedes.s3.us-west-2.amazonaws.com/sites/fulcrum/graphics/mindfulMaterials.png',
  leed: 'https://ecomedes.s3.us-west-2.amazonaws.com/sites/fulcrum/graphics/leed.png',
  well: 'https://ecomedes.s3.us-west-2.amazonaws.com/sites/fulcrum/graphics/well.png',
  ilfi: 'https://ecomedes.s3.us-west-2.amazonaws.com/sites/fulcrum/graphics/ilfi.png'
};

export const ContactUs = ({ trackFn, openModal }: ContactUsProps): JSX.Element => {
  const handleEvaluateClick = (): void => {
    openModal('document-contributions');
    trackFn && trackFn('eval-view');
  };

  return (
    <div className={style.section}>
      <Container>
        <div className={`${style.container} hidPdfElements`}>
          <IconBox icon={<Rating productDetail />} className={style.icon} />
          <h3 className={style.title}>Rating Systems, Standards, & Frameworks</h3>
          <p className={style.content}>
            Check credits for mindful Materials, LEED, WELL, Living Building Challenge, and other
            ratings systems, standards, and frameworks. To access sign-in or register for free.
          </p>
          <div className={style.logos}>
            {Object.keys(logoUrls).map((key) => (
              <img key={key} src={logoUrls[key]} alt={key} className={style.logo} />
            ))}
          </div>
          <div className={`${style.evaluateButton} pdfContentHide`}>
            <CustomButton
              label="EVALUATE CREDITS"
              className={style.evaluateButtonStyle}
              onClick={handleEvaluateClick}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
