import React from 'react';

import style from './customLinkButton.module.scss';
interface CustomLinkButtonProps {
  Icon?: any;
  text?: string;
  buttonClassname?: string;
  link?: string;
  onClick?: () => void;
}
const CustomLinkButton: React.FC<CustomLinkButtonProps> = ({
  Icon,
  text,
  buttonClassname,
  link = '',
  onClick
}) => (
  <a
    target="_blank"
    {...(link && { href: link })}
    className={`${style.buttonContainer} ${buttonClassname}`}
    onClick={onClick}
    rel="noreferrer">
    <h3>{text}</h3>
    {Icon}
  </a>
);

export default CustomLinkButton;
