import { convertFractionToPercentage } from '../../../utils/util';

import style from './manufacturingLocation.module.scss';

export const ManufacturingLocation = ({
  city,
  postRc,
  preRc,
  epdUrl,
  state,
  zipcode,
  index
}: {
  city?: string;
  postRc?: number;
  preRc?: number;
  epdUrl?: string;
  state?: string;
  zipcode?: string;
  index?: number;
}): JSX.Element => (
  <div className={style.manufacturingLocation}>
    <div className={index === 0 ? style.closestLocationSection : style.locationSection}>
      {index === 0 && <div className={style.manufacturingLocationMainChip}>Closest location</div>}
      <div className={style.manufacturingLocationMainLocation}>
        <div className={style.manufacturingLocationMainTitle}>
          {` ${city}, ${state} ${zipcode}`}
        </div>
        <div className={style.manufacturingLocationMainDash}>-</div>
      </div>
    </div>
    <div className={style.manufacturingLocationRow}>
      <div className={style.manufacturingLocationDetails}>
        <div>Environmental Product Declaration (EPD)</div>
        <div>{epdUrl}</div>
      </div>
      <div className={style.manufacturingLocationDetails}>
        <div>Preconsumer</div>
        <div>{preRc && convertFractionToPercentage(preRc)}</div>
      </div>
      <div className={style.manufacturingLocationDetails}>
        <div>Postconsumer</div>
        <div>{postRc && convertFractionToPercentage(postRc)}</div>
      </div>
    </div>
  </div>
);
