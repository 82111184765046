export const ThumbsUpIcon = (): JSX.Element => (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_254_729)">
      <path
        d="M3.5 11.875H8.75V23.25H3.5C3.26794 23.25 3.04538 23.1578 2.88128 22.9937C2.71719 22.8296 2.625 22.6071 2.625 22.375V12.75C2.625 12.5179 2.71719 12.2954 2.88128 12.1313C3.04538 11.9672 3.26794 11.875 3.5 11.875Z"
        stroke="black"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 11.875L13.125 3.125C14.0533 3.125 14.9435 3.49375 15.5999 4.15013C16.2563 4.8065 16.625 5.69674 16.625 6.625V9.25H23.625C23.8732 9.25007 24.1186 9.30295 24.3449 9.40514C24.5711 9.50732 24.773 9.65647 24.9372 9.84267C25.1013 10.0289 25.224 10.2479 25.2971 10.4851C25.3701 10.7224 25.3918 10.9725 25.3608 11.2188L24.0483 21.7188C23.995 22.1416 23.7893 22.5304 23.4697 22.8123C23.1501 23.0943 22.7387 23.2499 22.3125 23.25H8.75"
        stroke="black"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_254_729">
        <rect width="28" height="28" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
