import React from 'react';

import style from './productDetails.module.scss';
interface ModalHeaderProps {
  Icon?: any;
  label?: string;
  description?: string;
  closeIcon?: any;
  setIsModalOpen?: () => void;
  className?: string;
}
export const ModalHeader: React.FC<ModalHeaderProps> = ({
  Icon,
  label,
  description,
  closeIcon,
  setIsModalOpen,
  className
}) => (
  <div className={`${style.modalHeaderAddProjectContainer} ${className ? className : ''}`}>
    <div className={style.headerContent}>
      <div className={style.modalHeaderTitleandIcon}>
        {Icon}
        <h1 className={style.modalHeaderLabel}>{label}</h1>
      </div>
      <p className={style.modalHeaderDescription}>{description}</p>
    </div>
    <span onClick={setIsModalOpen} style={{ cursor: 'pointer' }}>
      {closeIcon}
    </span>
  </div>
);
