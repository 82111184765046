import { useRef } from 'react';

import style from './greenTags.module.scss';

const GreenTagsContainer = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const sectionRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <div ref={sectionRef} className={style.greenTagContainer}>
        {children}
      </div>
    </div>
  );
};

export default GreenTagsContainer;
