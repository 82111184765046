import { mountAISearch, unmountAISearch } from './aiSearch';
import { mountAISummary, unmountAISummary } from './AISummary';
import { mountProductDetail, unmountProductDetail } from './productDetail';
import { mountProductEvaluation, unmountProductEvaluation } from './productEvaluation';
import { mountProjectEvaluation, unmountProjectEvaluation } from './projectEvaluation';
import { mountImpactSummary, unmountImpactSummary } from './sustainabilitySummary';

interface IComponents {
  mount: {
    mountImpactSummary: (
      element: HTMLElement,
      projectName: Record<string, any>,
      reportData: Record<string, any>,
      backToProject: () => {},
      trackFn: (action: string) => {}
    ) => void;
    mountProjectEvaluation: (
      element: HTMLElement,
      projectName: Record<string, any>,
      reportData: Record<string, any>,
      backToProject: () => {},
      trackFn: (action: string) => {}
    ) => void;
    mountProductEvaluation: (
      element: HTMLElement,
      projectName: Record<string, any>,
      reportData: Record<string, any>,
      trackFn: (action: string) => void
    ) => void;
    mountAISummary: (element: HTMLElement) => void;
    mountProductDetail: (element: HTMLElement, reportData: Record<string, any>) => void;
    mountAISearch: (element: HTMLElement, reportData: Record<string, any>) => void;
  };
  unmount: {
    unmountImpactSummary: () => void;
    unmountProjectEvaluation: () => void;
    unmountProductEvaluation: () => void;
    unmountAISummary: () => void;
    unmountProductDetail: () => void;
    unmountAISearch: () => void;
  };
}

declare global {
  interface Window {
    components: IComponents;
  }
}

(function (window) {
  const myLibrary = {
    components: {
      mount: {
        mountImpactSummary,
        mountProjectEvaluation,
        mountProductEvaluation,
        mountAISummary,
        mountProductDetail,
        mountAISearch
      },
      unmount: {
        unmountImpactSummary,
        unmountProjectEvaluation,
        unmountProductEvaluation,
        unmountAISummary,
        unmountProductDetail,
        unmountAISearch
      }
    }
  };

  window.components = myLibrary.components;
})(window);
