import React, { useEffect, useState } from 'react';
import { JSX } from 'react/jsx-runtime';

import { CustomInput } from '../shared/CustomInput';
import { CustomMenu } from '../shared/CustomMenu';
import { CustomSelect } from '../shared/CustomSelect';

import { MODALTYPE } from './modaltypes';

import style from './productDetails.module.scss';
interface ModalBodyProps {
  ratings?: boolean;
  addProject?: boolean;
  setshowFields?: any;
  addProjectHandlers?: any;
  setSelectedOption?: (option: any) => void;
  selectedOption?: any;
  projectName?: string | number;
  setProjectName?: (value: string) => void;
  postalCode?: string | number;
  setPostalCode?: (value: string | number) => void;
  ratingsData?: any;
  modalType?: any;
}
const ModalBody: React.FC<ModalBodyProps> = ({
  ratings = false,
  addProject = false,
  setshowFields,
  addProjectHandlers,
  setSelectedOption,
  selectedOption,
  projectName,
  setProjectName,
  postalCode,
  setPostalCode,
  ratingsData = [],
  modalType = ''
}) => {
  const [selectProjectOptions, setSelectProjectOptions] = useState([]);
  const [selectRatingsOptions, setSelectRatingsOption] = useState([]);
  const [isMenuOpen, setMenuIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (modalType === MODALTYPE.ADD_TO_PROJECT) {
      const getProjects = async (): Promise<any> => {
        try {
          const projectListResponse = await addProjectHandlers?.getProjects();
          const projectList =
            !!projectListResponse &&
            projectListResponse.map((item: any) => ({
              id: item?.id,
              value: item?.name || '',
              label: item?.name || ''
            }));

          setSelectProjectOptions(projectList);
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      };

      getProjects();
    }
  }, [addProjectHandlers, modalType]);

  useEffect(() => {
    if (modalType === MODALTYPE.DOCUMENT_CONTRIBUTIONS && ratingsData) {
      const ratingList = ratingsData?.map((item: any) => ({
        id: item?.id,
        value: item?.name || '',
        label: item?.name || ''
      }));

      setSelectRatingsOption(ratingList);
    }
  }, [modalType, ratingsData]);

  type ModalType = MODALTYPE.ADD_TO_PROJECT | MODALTYPE.DOCUMENT_CONTRIBUTIONS;
  const selecList: Record<ModalType, any[]> = {
    [MODALTYPE.ADD_TO_PROJECT]: selectProjectOptions ? selectProjectOptions : [],
    [MODALTYPE.DOCUMENT_CONTRIBUTIONS]: selectRatingsOptions ? selectRatingsOptions : []
  };
  const options = modalType ? selecList[modalType as ModalType] : undefined;

  return (
    <div>
      <form className={style.addProjectModalForm}>
        <CustomSelect
          label={ratings ? 'Rating Systems, Standards, and Frameworks' : 'Projects'}
          options={options || []}
          addProjectHandlers={addProjectHandlers}
          addProject={addProject}
          setshowFields={setshowFields}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setMenuIsOpen={setMenuIsOpen}
          modalType={modalType}
          menuIsOpen={isMenuOpen}
          {...(!ratings && {
            components: (props: JSX.IntrinsicAttributes) => (
              <CustomMenu
                {...props}
                setshowFields={setshowFields}
                setSelectedOption={setSelectedOption}
                setMenuIsOpen={setMenuIsOpen}
              />
            )
          })}
          placeholder={
            ratings ? 'Select a rating system, standard, or framework' : 'Select project'
          }
        />

        {!ratings && !addProject && (
          <div className={style.addProjectModalFormInputContainer}>
            <CustomInput
              type="text"
              label={'Project Name'}
              placeholder={'Enter project name'}
              value={projectName || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (setProjectName) {
                  setProjectName(e.target.value);
                }
              }}
            />
          </div>
        )}
        {!addProject && (
          <div className={style.addProjectModalFormInputContainer}>
            <CustomInput
              type="text"
              label={ratings ? 'Postal / Zip Code' : 'Postal Code'}
              placeholder={'Enter postal code'}
              value={postalCode || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (setPostalCode) {
                  setPostalCode(e.target.value);
                }
              }}
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default ModalBody;
