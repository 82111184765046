// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const pdfStyle = () => `

    kendo-pdf-document .hidePdfElements {
        display: none!important;
    }
    kendo-pdf-document .paddingPdf {
        padding-top: 0;
    }

    kendo-pdf-document .displayPdfBrandLogo {
        display: flex!important;
        justify-content: flex-start;
        width: 100%;
        visibility: visible;
        background-color: white !important;
        width: 200%;
        margin-left: -30px;
        border-bottom: 12px solid gray;
        padding: 10px 0;
        margin-top: -10px
    }
    kendo-pdf-document .pdfBrandLogo {
        height: 50px;
    }
 
    kendo-pdf-document .impactSummaryHeaderSecttion {
        flex-direction: row !important;
        align-items: center !important;
    }
    kendo-pdf-document .detailItemsPdf {
        flex-direction: row !important;
        align-items: flex-start !important;
        padding: 1rem 0 2rem 0 !important;
    }
    kendo-pdf-document .statisticsPdf {
        flex-direction: row !important;

    }
    kendo-pdf-document .iconBoxInfoSectionPdfContent {
        margin-top: -5px !important

    }
    kendo-pdf-document .postiveImpactSummaryHeadingPdf {
        margin-top: -5px !important
    }
    kendo-pdf-document  .marginMinimumAddresspdf {
        marginTop: 1rem !important
    }
    kendo-pdf-document  .marginMaximumAddresspdf {
        marginTop: 5rem !important
    }
    kendo-pdf-document.statisticItemPdf {
        width: 33% important;
    }
    kendo-pdf-document .pdfAccordionHeaderName{
        text-underline-offset: 5px !important;
    }
    kendo-pdf-document .impactSummaryTitlepdf {
        width: 100% !important;
    }
    kendo-pdf-document .pdfTableTopMargin {
        margin-top: 1rem !important
    }
    kendo-pdf-document .pdfAccordionIconInvisible{
        display: none;

    }
    kendo-pdf-document .pdfContentShow{
        max-height: fit-content !important;
        transition: max-height 0.3s ease;
    }
    kendo-pdf-document th {
        border: 0px solid white;
    }
    kendo-pdf-document table {  
        border-spacing: 0px !important; 
    }
    kendo-pdf-document .productLinkUnderLine{
        text-underline-offset: 5px !important;
        text-decoration-color: #707070;
        text-decoration-thickness: from-font;
    } 
    kendo-pdf-document .subAddressPdf {
        height: 30px;
    }

    kendo-pdf-document .projectTitlepdf {
        width: 100% !important;
    }

    kendo-pdf-document .projectEvalHeaderSection {
        flex-direction: row !important;
        align-items: center !important;
    }
    
    kendo-pdf-document .pdfContentHide {
        display: none !important
    }

    kendo-pdf-document .pdfactionButtonSection {
        height: 100% !important
    }
   
`;
