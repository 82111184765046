import React, { useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';

import { ProductDetail } from '../components/ProductDetail';
import { ErrorBoundaryWrapper } from '../components/shared/ErrorBoundary/ErrorBoundary';

import style from './sustainabilitySummaryPage.module.scss';
let root: Root | null = null;

export function mountProductDetail(element: HTMLElement, reportData: Record<string, any>): void {
  const defaultProps = {
    data: reportData
  };

  if (!root) {
    root = createRoot(element);
  }

  root.render(<ProductPage {...defaultProps} />);
}

export function unmountProductDetail(): void {
  if (root) {
    root.unmount();
    root = null;
  }
}
const ProductPage: React.FC<{ data?: Record<string, any> }> = ({ data }) => {
  const pdfExportComponent = React.useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      unmountProductDetail();
    },
    []
  );

  return (
    <div className={style.sustainabilitySummaryPageContainer}>
      <div ref={pdfExportComponent}>
        <ErrorBoundaryWrapper>
          <ProductDetail data={data} pdfRef={pdfExportComponent} />
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};

export default ProductPage;
