import { useEffect, useState } from 'react';

import { formConfigurations } from './formConfiguration';

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: any) => {
          onFormSubmitted: (callback: () => void) => void;
          onFormReady?: (callback: ($form: JQuery<HTMLElement>) => void) => void;
        };
      };
    };
  }
}

interface HubSpotFormProps {
  formId: string;
  toggle?: () => void;
  setIsModalOpen: () => void;
  setshowCrossIcon?: (action: boolean) => void;
  data?: any;
}

const HubSpotForm: React.FC<HubSpotFormProps> = ({
  formId,
  toggle,
  setIsModalOpen,
  setshowCrossIcon,
  data
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    const onScriptLoad = (): void => {
      setLoading(false);

      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '617005',
          formId: formId,
          target: '#hubspotForm',
          onFormReady: formConfigurations[formId]?.onFormReady
            ? ($form: JQuery<HTMLElement>) => formConfigurations[formId]?.onFormReady?.($form, data)
            : undefined,
          onFormSubmitted: () => {
            if (setshowCrossIcon) {
              setshowCrossIcon(false);
            }

            setTimeout(() => {
              setIsModalOpen();
            }, 2000);
          },
          onFormError: (error: Error) => {
            console.error('Form submission error:', error);
          }
        });
      }
    };

    script.addEventListener('load', onScriptLoad);

    return () => {
      script.removeEventListener('load', onScriptLoad);
    };
  }, [formId, toggle, setIsModalOpen, setshowCrossIcon, data]);

  return <div id="hubspotForm">{loading && <p>Loading...</p>}</div>;
};

export default HubSpotForm;
