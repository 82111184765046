import React, { ReactNode } from 'react';

interface HubspotFormContainerProps {
  children: ReactNode;
  className?: string;
}
const HubspotFormContainer: React.FC<HubspotFormContainerProps> = ({ children, className }) => (
  <div className={className || ''}>{children}</div>
);

export default HubspotFormContainer;
