export const baseURL = ({
  productUrl,
  id,
  pathname
}: {
  productUrl?: string;
  id?: string;
  pathname?: string;
}): string => {
  const url = window.location;
  const baseUrl = id
    ? `${url.protocol}//${url.host}${pathname}/evaluation?rating-system=${id}`
    : `${url.protocol}//${url.host}${productUrl}`;

  return baseUrl;
};
