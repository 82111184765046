import { RefObject, useEffect, useState } from 'react';

import { DownloadPdf } from '../../assets/svgs/downloadPdf';
import UnitsIcon from '../../assets/svgs/units';
import { handlePDFDownload } from '../../utils/pdf';
import SantizedHTMLRawData from '../../utils/sanitizeHTML';
import { gridIcons } from '../ProjectEvaluation';
import { generatePageTemplate } from '../ProjectEvaluation/Header';
import Accordion from '../shared/Accordian/Accordion';
import AccordionHeader from '../shared/AccordionHeaderContent/AccordionHeader';
import AccordionRow from '../shared/AccordionRow/AccordionRow';
import RCButton from '../shared/Button/Button';
import { Container } from '../shared/Container';
import FallbackContainer from '../shared/ErrorBoundary/FallbackContainer';
import Loader from '../shared/Loader/loader';
import ProductContributionGridHeader from '../shared/ProductContributionGridHeader/ProductContributionGridHeader';

import style from './productEvaluation.module.scss';

interface AccordionData {
  id: number;
  value: boolean;
}

const ProductEvaluation = ({
  data,
  pdfRef,
  trackFn,
  projectName
}: {
  data: Record<string, any> | undefined;
  pdfRef: RefObject<HTMLDivElement>;
  projectName: Record<string, any> | undefined;
  trackFn?: (action: string) => void;
}): JSX.Element => {
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [showErrorBoundaryinPDF, setShowErrorBoundaryinPDF] = useState<any>({});
  const [accordionContentShow, setaccordionContentShow] = useState<AccordionData[]>([]);
  const onHeaderClick = (index: number): any => {
    setaccordionContentShow((prevData) =>
      prevData?.map((data) => ({
        ...data,
        value: Number(data.id) === Number(index) ? !data.value : data.value
      }))
    );
  };

  useEffect(() => {
    setaccordionContentShow(
      data?.lens_data.map((data: any, index: number) => ({
        id: index,
        value: true
      }))
    );
  }, [data]);

  return (
    <div className={style.productEvaluationSection}>
      <div className={style.productMedaDataSection}>
        <div className={`${style.notVisibleLogo} displayPdfBrandLogo`}>
          <img
            className="pdfBrandLogo"
            src={projectName?.pdf_logo || projectName?.ecomedes_logo}
            alt="logo"
          />
        </div>
        <Container>
          <div className={style.productDetails}>
            <div className={style.productImgSection}>
              <img src={data?.product.thumbnail} alt="product" className={style.productImg} />
            </div>
            <div className={style.productMetaDataContainer}>
              <div className={style.productMetaData}>
                <div className={style.productCategory}>
                  {!!data?.product?.key_features &&
                    data.product.key_features?.map((feature: any) => (
                      <div className={style.productCategorykeys}>
                        <div className={style.productCategoryInformation}>{feature?.name}:</div>
                        <div className={style.productCategoryValues}>
                          {Array.isArray(feature?.value) ? (
                            <div>{feature.value.join(', ')}</div>
                          ) : (
                            <div>{feature?.value}</div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div className={style.productBrandName}>{data?.product?.brand_name || ''}</div>
                <div className={style.productName}>{data?.product?.name || ''}</div>
                <div className={style.productDescription}>
                  <SantizedHTMLRawData content={data?.product?.description || ''} />
                </div>
              </div>
              <div className={style.downloadButtons}>
                <RCButton
                  onClick={() => {
                    handlePDFDownload(
                      pdfRef,
                      setIsPdfLoading,
                      generatePageTemplate,
                      `${data?.product.brand_name?.toLowerCase()}-${data?.product.sku}-ecomedes-evaluation`,
                      projectName
                    );

                    if (trackFn) {
                      trackFn('eval-pdf');
                    }
                  }}
                  className={`${style.pdfButton} ${style.downloadButtonContent} hidePdfElements`}
                  variant="primary">
                  <span className={style.downloadPdfText}>DOWNLOAD PDF</span>
                  {isPdfLoading ? <Loader /> : <DownloadPdf productEval />}
                </RCButton>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className={style.ratingSystem}>
        <Container>
          <div className={style.ratingSystemHeader}>Rating System Contributions</div>
          {!!data?.evaluations &&
            data?.evaluations?.map((item: any, index: number) => (
              <FallbackContainer
                component="Accordion"
                showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                <Accordion
                  key={index}
                  headerContent={
                    <FallbackContainer
                      component="AccordionHeader"
                      showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                      setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                      <AccordionHeader
                        icon={gridIcons[item?.icon] || <UnitsIcon />}
                        title={item?.name || ''}
                        key={`${item?.name}-${index}`}
                      />
                    </FallbackContainer>
                  }>
                  <div>
                    <div className={style.productTable}>
                      {item?.details &&
                        item?.details?.map((row: any, evalIndex: number) => (
                          <>
                            {item?.details[evalIndex]?.details?.length === 0 ? (
                              <FallbackContainer
                                component="AccordionRow"
                                showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                <AccordionRow
                                  title={row?.name || ''}
                                  value={row?.value || ''}
                                  type={typeof row?.value || ''}
                                  creditName={row?.display_id || ''}
                                  key={`row-${evalIndex}`}
                                  isLightContent
                                  index={evalIndex}
                                  dataLength={item?.details?.length}
                                  regWall={data?.regWall}
                                  isSignedIn={true}
                                />
                              </FallbackContainer>
                            ) : (
                              <FallbackContainer
                                component="AccordionRow"
                                showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                <AccordionRow
                                  title={row?.name || ''}
                                  value={row?.value || ''}
                                  type={typeof row?.value || ''}
                                  creditName={row?.display_id || ''}
                                  key={`row-${evalIndex}`}
                                  isLightContent
                                  regWall={data?.regWall}
                                  isSignedIn={true}
                                />
                              </FallbackContainer>
                            )}
                            {item?.details[evalIndex]?.details &&
                              item?.details[evalIndex]?.details?.map((row: any, index: number) => (
                                <FallbackContainer
                                  component="AccordionRow"
                                  showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                  setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                  <AccordionRow
                                    title={row?.name || ''}
                                    value={
                                      typeof row?.value === 'object'
                                        ? row.value.value
                                        : row?.value || ''
                                    }
                                    type={row?.kind || ''}
                                    key={`data-${index}`}
                                    isLightContent
                                    isCreditDetails
                                    nestedRow={
                                      item?.details?.length - 1 === evalIndex &&
                                      item?.details[evalIndex]?.details.length - 1 === index
                                    }
                                    regWall={data?.regWall}
                                    isSignedIn={true}
                                  />
                                </FallbackContainer>
                              ))}
                          </>
                        ))}
                    </div>
                  </div>
                </Accordion>
              </FallbackContainer>
            ))}
          <div className={style.ratingSystemHeader}>Details</div>
          {!!data?.lens_data &&
            data?.lens_data?.map((lensValue: any, lensIndex: number): any => (
              <div style={{ marginBottom: '2rem' }}>
                <div key={`lens-${lensIndex}`}>
                  {lensValue?.details?.details?.length > 0 ||
                  lensValue?.details?.locations.length > 0 ? (
                    <FallbackContainer
                      component="ProductContributionGridHeader"
                      showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                      setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                      <ProductContributionGridHeader
                        key={`header-${lensIndex}`}
                        name={lensValue?.name || ''}
                        image={lensValue?.image || ''}
                        notes={lensValue?.notes || ''}
                        lensDetails={lensValue?.details?.details || []}
                        productPage
                        onHeaderClick={onHeaderClick}
                        index={lensIndex}
                        accordionContentShow={accordionContentShow}
                      />
                    </FallbackContainer>
                  ) : (
                    <div></div>
                  )}
                  <div className={style.productTable}>
                    <div
                      className={`${
                        accordionContentShow[lensIndex]?.value
                          ? `${style.contentShow} ${style.transition}`
                          : `${style.contentHide} ${style.transition}`
                      } pdfContentShow`}>
                      {lensValue?.details?.details ? (
                        <div>
                          {lensValue?.details?.details?.map(
                            (detailsData: any, detailsIndex: number) => (
                              <FallbackContainer
                                component="AccordionRow"
                                showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                <AccordionRow
                                  key={`row-${detailsData}-${detailsIndex}`}
                                  title={detailsData?.name || ''}
                                  value={detailsData?.value || ''}
                                  type={detailsData?.kind || ''}
                                  {...(detailsData?.hint && { tooltipText: detailsData?.hint })}
                                  isLensData
                                  isLightContent
                                  regWall={data?.regWall}
                                  isSignedIn={true}
                                />
                              </FallbackContainer>
                            )
                          )}
                          <div
                            onClick={() => onHeaderClick(lensIndex)}
                            className={`${style.accordionFooter} pdfContentHide`}>
                            {accordionContentShow[lensIndex]?.value ? 'SHOW LESS ' : 'SHOW MORE'}
                          </div>
                        </div>
                      ) : (
                        lensValue?.details?.locations?.length > 0 && (
                          <div>
                            {lensValue?.details?.locations?.map(
                              (locationDetails: any, locationIndex: number) => (
                                <FallbackContainer
                                  component="AccordionRow"
                                  showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                                  setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                                  <AccordionRow
                                    key={`row-${locationDetails}-${locationIndex}`}
                                    city={locationDetails?.city || ''}
                                    postRc={locationDetails?.post_rc || ''}
                                    preRc={locationDetails?.pre_rc || ''}
                                    state={locationDetails?.state || ''}
                                    zipcode={locationDetails?.zipcode || ''}
                                    index={locationIndex}
                                    isManufactureLocation
                                    regWall={data?.regWall}
                                    isSignedIn={true}
                                  />
                                </FallbackContainer>
                              )
                            )}
                            <div
                              onClick={() => onHeaderClick(lensIndex)}
                              className={`${style.accordionFooter} pdfContentHide`}>
                              {accordionContentShow[lensIndex]?.value ? 'SHOW LESS ' : 'SHOW MORE'}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Container>
      </div>
    </div>
  );
};

export default ProductEvaluation;
