import React from 'react';

import { AISearchTitleIcon } from '../../../../assets/svgs/aiSearchTitleIcon';
import AISummaryContainer from '../aisumaryContainer';

const ModalHeader: React.FC = () => (
  <AISummaryContainer
    title="IntelliBuild"
    Icon={<AISearchTitleIcon strokeColor={'black'} />}
    description="Try IntelliBuild product finder to find products using natural language. Simply ask for what you need by category, certification, or brand and get product recommendations. Or search for a specific product by name."
  />
);

export default ModalHeader;
