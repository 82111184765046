// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DVlLDzWVdy5ZkD6Kounv{display:flex;gap:.5rem;align-items:flex-start}.DVlLDzWVdy5ZkD6Kounv .Y0Y6otARGhyXJkhDLIR8{display:flex;flex-direction:column}.DVlLDzWVdy5ZkD6Kounv .d1oZohQazX2umoHIoG1z{color:var(--ecd-gray-secondary);font-family:"Gotham600";font-size:12px;margin-top:.5rem}.DVlLDzWVdy5ZkD6Kounv .j6oGCg42LsOMWckypxKD{color:var(--ecd-gray-secondary);font-family:"Gotham500";font-size:12px;font-style:italic}`, "",{"version":3,"sources":["webpack://./src/components/AISearch/AISearchModal/mainSummaryContainer/mainSummaryContainer.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,SAAA,CACA,sBAAA,CAEA,4CACI,YAAA,CACA,qBAAA,CAGJ,4CACI,+BAAA,CACA,uBAAA,CACA,cAAA,CACA,gBAAA,CAGJ,4CACI,+BAAA,CACA,uBAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".mainSummaryContainer {\n    display: flex;\n    gap: 0.5rem;\n    align-items: flex-start;\n\n    .mainSummaryContainerIcon {\n        display: flex;\n        flex-direction: column;\n    }\n\n    .modalmainSummaryDescription {\n        color: var(--ecd-gray-secondary);\n        font-family: 'Gotham600';\n        font-size: 12px;\n        margin-top: 0.5rem;\n    }\n\n    .productSummaryDescription {\n        color: var(--ecd-gray-secondary);\n        font-family: 'Gotham500';\n        font-size: 12px;\n        font-style: italic;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainSummaryContainer": `DVlLDzWVdy5ZkD6Kounv`,
	"mainSummaryContainerIcon": `Y0Y6otARGhyXJkhDLIR8`,
	"modalmainSummaryDescription": `d1oZohQazX2umoHIoG1z`,
	"productSummaryDescription": `j6oGCg42LsOMWckypxKD`
};
export default ___CSS_LOADER_EXPORT___;
