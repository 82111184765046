import React from 'react';

import style from './customModalHeader.module.scss';
const CustomModalHeader: React.FC = () => (
  <div className={style.customModalHeader}>
    <p className={style.customModalHeaderLabel}>Please Provide your Feedback </p>
  </div>
);

export default CustomModalHeader;
