import React from 'react';

import style from './productDetails.module.scss';
interface ModalFooterProps {
  onClose?: any;
  onSubmit?: () => void;
}
export const ModalFooter: React.FC<ModalFooterProps> = ({ onClose, onSubmit }) => (
  <div className={style.modalFooterAddProjectContainer}>
    <button
      className={`${style.modalButton} ${style.modalFooterSaveBtn}`}
      onClick={() => {
        if (onSubmit) {
          onSubmit();
        }
      }}>
      SUBMIT
    </button>
    <button
      className={`${style.modalButton} ${style.modalFooterCloseBtn}`}
      onClick={() => onClose()}>
      CLOSE
    </button>
  </div>
);
