import { useEffect, useRef, useState } from 'react';

type Size = {
  width: number;
  height: number;
};

const useElementSize = (): [React.RefObject<HTMLElement>, Size] => {
  const ref = useRef<HTMLElement | null>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]): any => {
      for (const entry of entries) {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        });
      }
    };
    const observer = new ResizeObserver(handleResize);

    if (ref.current) {
      observer.observe(ref.current);
    }

    // Cleanup observer on unmount
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return [ref, size];
};

export default useElementSize;
