import React, { useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';

import { ErrorBoundaryWrapper } from '../components/shared/ErrorBoundary/ErrorBoundary';
import SustainabilitySummary from '../components/SustainabilitySummary';
import { isValidObject } from '../utils/filterTableData';

import ProductData from './../utils/data.json';

import style from './sustainabilitySummaryPage.module.scss';

let root: Root | null = null;

export function unmountImpactSummary(): void {
  if (root) {
    root.unmount();
    root = null;
  }
}
export function mountImpactSummary(
  element: HTMLElement,
  projectName: Record<string, any>,
  reportData: Record<string, any>,
  backToProject: () => any,
  trackFn: (action: string) => any
): void {
  const defaultProps = {
    productList: isValidObject(reportData) ? reportData : ProductData,
    backToProject: backToProject,
    projectName: projectName,
    trackFn: trackFn
  };

  if (!root) {
    root = createRoot(element);
  }

  root.render(<SustainabilitySummaryPage {...defaultProps} />);
}

const SustainabilitySummaryPage: React.FC<{
  productList?: any;
  backToProject?: any;
  projectName?: any;
  trackFn?: any;
}> = ({ productList, backToProject, projectName, trackFn }) => {
  const pdfExportComponent = React.useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      unmountImpactSummary();
    },
    []
  );

  return (
    <div className={style.sustainabilitySummaryPageContainer}>
      <div ref={pdfExportComponent} className="sustainabilitySummaryPageContainerPdf">
        <ErrorBoundaryWrapper>
          <SustainabilitySummary
            productList={productList ?? ProductData}
            pdfRef={pdfExportComponent}
            backToProject={backToProject}
            projectName={projectName}
            trackFn={trackFn}
          />
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};

export default SustainabilitySummaryPage;
