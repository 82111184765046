import React from 'react';

import { CustomInput } from '../../../../shared/CustomInput';

interface CustomModalBodyProps {
  onChange?: any;
  value?: string;
}
const CustomModalBody: React.FC<CustomModalBodyProps> = ({ onChange, value }) => (
  <div>
    <CustomInput type={'textarea'} placeholder="comments" onChange={onChange} value={value} />
  </div>
);

export default CustomModalBody;
