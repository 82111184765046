/* eslint-disable max-params */
import React, { useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';

import ProductEvaluation from '../components/ProductEvaluation';
import { ErrorBoundaryWrapper } from '../components/shared/ErrorBoundary/ErrorBoundary';
import { Redirect } from '../components/shared/Redirect/redirect';
import { isValidObject } from '../utils/filterTableData';
import { productEvaluationData } from '../utils/productEvaluation';

import style from './sustainabilitySummaryPage.module.scss';
let root: Root | null = null;

export function mountProductEvaluation(
  element: HTMLElement,
  projectName: Record<string, any>,
  reportData: Record<string, any>,
  trackFn: (action: string) => void
): void {
  const defaultProps = {
    data: isValidObject(reportData) ? reportData : productEvaluationData,
    projectName: projectName,
    trackFn: trackFn
  };

  if (!root) {
    root = createRoot(element); // Only create root if it doesn't exist
  }

  root.render(<ProductEvaluationPage {...defaultProps} />);
}

export function unmountProductEvaluation(): void {
  if (root) {
    root.unmount(); // Correct method for unmounting
    root = null; // Reset root reference after unmounting
  }
}
const ProductEvaluationPage: React.FC<{
  data?: Record<string, any>;
  projectName?: Record<string, any>;
  trackFn?: (action: string) => void;
}> = ({ data, projectName, trackFn }) => {
  const pdfExportComponent = React.useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      unmountProductEvaluation();
    },
    []
  );

  return (
    <div className={style.sustainabilitySummaryPageContainer}>
      <div ref={pdfExportComponent}>
        <Redirect specifier={'product evaluation'} />
        <ErrorBoundaryWrapper>
          <ProductEvaluation
            data={data}
            pdfRef={pdfExportComponent}
            projectName={projectName}
            {...(trackFn && { trackFn })}
          />
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};

export default ProductEvaluationPage;
