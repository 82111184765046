import React from 'react';

export const AddProjectIcon: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_20_6604)">
      <path
        d="M21 8.75V19.3334C21 19.5102 20.9298 19.6798 20.8048 19.8048C20.6798 19.9298 20.5102 20 20.3334 20H3.75C3.55109 20 3.36032 19.921 3.21967 19.7803C3.07902 19.6397 3 19.4489 3 19.25V6.5C3 6.30109 3.07902 6.11032 3.21967 5.96967C3.36032 5.82902 3.55109 5.75 3.75 5.75H8.74969C8.91197 5.75 9.06987 5.80263 9.19969 5.9L12 8H20.25C20.4489 8 20.6397 8.07902 20.7803 8.21967C20.921 8.36032 21 8.55109 21 8.75Z"
        stroke="black"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 14H14.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11.75V16.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_20_6604">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
