import style from './tooltip.module.scss';
const ToolTip = ({
  children,
  tooltipText = 'Tooltip',
  isLeftTooltip = false
}: {
  children: React.ReactNode;
  tooltipText?: string;
  isLeftTooltip?: boolean;
}): JSX.Element => (
  <div className={style.tooltip}>
    {children}
    <span className={`${style.tooltiptext} ${isLeftTooltip && style.tooltiptextleft}`}>
      {tooltipText}
    </span>
  </div>
);

export default ToolTip;
