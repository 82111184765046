import style from './skeleton.module.scss';
const Skeleton: React.FC = () => (
  <div className={style.skeleton} data-testid="skeleton">
    <div className={`${style.skeletonLeft} ${style.flex1}`} data-testid="skeleton-left">
      <div className={`${style.square} ${style.circle}`}></div>
    </div>
    <div className={`${style.skeletonRight} ${style.flex2}`} data-testid="skeleton-right">
      <div className={`${style.line} ${style.h17} ${style.w75} ${style.m10}`}></div>
      <div className={`${style.line} ${style.h17} ${style.w25} ${style.m10}`}></div>
    </div>
  </div>
);

export default Skeleton;
