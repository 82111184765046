import React from 'react';

export const CheckMarkIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_840_7365)">
      <path
        d="M2.5 9L6 12.5L14 4.5"
        stroke="#49B108"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_840_7365">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
