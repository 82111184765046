import React from 'react';

import style from '../../../../ProductDetail/productDetails.module.scss';
interface ModalFooterProps {
  onSubmit?: (buttontype?: string) => void;
}
export const CustomModalFooter: React.FC<ModalFooterProps> = ({ onSubmit }) => (
  <div className={style.modalFooterAddProjectContainer}>
    <button
      className={`${style.modalButton} ${style.modalFooterSaveBtn}`}
      onClick={() => {
        if (onSubmit) {
          onSubmit('submit');
        }
      }}>
      {'SUBMIT'}
    </button>
    <button
      className={`${style.modalButton} ${style.modalFooterCloseBtn}`}
      onClick={() => {
        if (onSubmit) {
          onSubmit('close');
        }
      }}>
      {'CLOSE'}
    </button>
  </div>
);
