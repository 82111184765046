interface FormConfiguration {
  onFormReady?: ($form: JQuery<HTMLElement>, data?: any) => void;
}
export enum FORMTYPE {
  CONTACT_MANUFACTURER = 'dca4dac0-f43f-4510-a5fc-2416b2bfd6cc',
  AI_SUMMARY = '5cd7805b-7c6e-45fb-a695-4c7d0f753d67'
}
export const formConfigurations: Record<string, FormConfiguration> = {
  [FORMTYPE.AI_SUMMARY]: {
    onFormReady: ($form, data) => {
      $form
        .find('input[name="ai_summary"]')
        .val(data?.ai_summary || '')
        .change();
      $form
        .find('input[name="brand_name"]')
        .val(data?.brand_ident || '')
        .change();
      $form
        .find('input[name="sku"]')
        .val(data?.sku || '')
        .change();
      $form
        .find('input[name="site_ident"]')
        .val(data?.site_ident || '')
        .change();
      $form
        .find('input[name="product_page"]')
        .val(data?.product_url || '')
        .change();
      $form.find('input[name="product_category"]').val('Product Category').change();
    }
  },
  [FORMTYPE.CONTACT_MANUFACTURER]: {
    onFormReady: ($form, data) => {
      $form
        .find('input[name="site_ident"]')
        .val(data?.site_ident || '')
        .change();
      $form
        .find('input[name="brand_name"]')
        .val(data['brand_name'] || '')
        .change();
      $form
        .find('input[name="sku"]')
        .val(data?.sku || '')
        .change();
      $form
        .find('input[name="brand"]')
        .val(data['brand'] || '')
        .change();
      $form
        .find('input[name="site_ident"]')
        .val(data?.site_ident || '')
        .change();
      $form
        .find('input[name="product_page"]')
        .val(data?.product_page || '')
        .change();
      $form
        .find('input[name="product_category"]')
        .val(data?.product_category || '')
        .change();
    }
  }
};
