import React from 'react';

const CartIcon: React.FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600061 2.57216e-05C0.268685 2.57216e-05 0 0.298565 0 0.66676C0 1.03496 0.268685 1.33338 0.600061 1.33338H1.67704L1.80782 2.10826L2.56928 8.09332C2.61107 8.42212 2.8644 8.66663 3.16333 8.66663H10.6286C10.9271 8.66663 11.1803 8.42266 11.2225 8.09421L11.994 2.09428C12.0186 1.90299 11.9672 1.70923 11.8533 1.56326C11.7393 1.41717 11.5739 1.33335 11.4 1.33335H2.89796L2.76477 0.54385C2.71154 0.228568 2.46374 0 2.17505 0L0.600061 2.57216e-05ZM3.68445 7.33336L3.09071 2.66685H10.708L10.108 7.33336H3.68445Z"
      fill="black"
    />
    <path
      d="M5.99998 10.6667C5.99998 11.4031 5.46269 12 4.79996 12C4.13724 12 3.59995 11.4031 3.59995 10.6667C3.59995 9.93037 4.13724 9.33337 4.79996 9.33337C5.46269 9.33337 5.99998 9.93037 5.99998 10.6667Z"
      fill="black"
    />
    <path
      d="M10.1999 10.6667C10.1999 11.4031 9.66257 12 8.99984 12C8.33712 12 7.79993 11.4031 7.79993 10.6667C7.79993 9.93037 8.33712 9.33337 8.99984 9.33337C9.66257 9.33337 10.1999 9.93037 10.1999 10.6667Z"
      fill="black"
    />
  </svg>
);

export default CartIcon;
