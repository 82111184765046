import React, { useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';

import AISearch from '../components/AISearch';
import { ErrorBoundaryWrapper } from '../components/shared/ErrorBoundary/ErrorBoundary';

import style from './sustainabilitySummaryPage.module.scss';
let root: Root | null = null;

export function mountAISearch(element: HTMLElement, data?: any): void {
  if (!root) {
    root = createRoot(element);
  }

  root.render(<AISearchPage data={data} />);
}

export function unmountAISearch(): void {
  if (root) {
    root.unmount();
    root = null;
  }
}
const AISearchPage: React.FC<any> = ({ data }) => {
  const pdfExportComponent = React.useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      unmountAISearch();
    },
    []
  );

  return (
    <div className={style.sustainabilitySummaryPageContainer}>
      <div ref={pdfExportComponent}>
        <ErrorBoundaryWrapper>
          <AISearch data={data} />
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};

export default AISearchPage;
