import { useEffect } from 'react';

type KeyPressHandler = () => void;

const useKeyPress = (onEscape?: KeyPressHandler, onEnter?: KeyPressHandler): any => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): any => {
      if (event.key === 'Escape' && onEscape) {
        onEscape();
      } else if (event.key === 'Enter' && onEnter) {
        onEnter();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscape, onEnter]);
};

export default useKeyPress;
