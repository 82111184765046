import React from 'react';

interface CustomButtonProps {
  label?: string;
  className?: string;
  onClick?: any;
}
const CustomButton: React.FC<CustomButtonProps> = ({ label, className, onClick }) => (
  <div onClick={onClick} className={className}>
    {label}
  </div>
);

export default CustomButton;
