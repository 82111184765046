import React from 'react';

const Wind: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_545_4767)">
      <path
        d="M12 18C12.3178 18.8578 13.2816 19.5 14.25 19.5C14.8467 19.5 15.419 19.2629 15.841 18.841C16.2629 18.419 16.5 17.8467 16.5 17.25C16.5 16.6533 16.2629 16.081 15.841 15.659C15.419 15.2371 14.8467 15 14.25 15H3.75"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6C9.31781 5.14219 10.2816 4.5 11.25 4.5C11.8467 4.5 12.419 4.73705 12.841 5.15901C13.2629 5.58097 13.5 6.15326 13.5 6.75C13.5 7.34674 13.2629 7.91903 12.841 8.34099C12.419 8.76295 11.8467 9 11.25 9H2.25"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 9C17.5678 8.14219 18.5316 7.5 19.5 7.5C20.0967 7.5 20.669 7.73705 21.091 8.15901C21.5129 8.58097 21.75 9.15326 21.75 9.75C21.75 10.3467 21.5129 10.919 21.091 11.341C20.669 11.7629 20.0967 12 19.5 12H3"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_545_4767">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Wind;
