import React, { useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import ProjectEvaluation from '../components/ProjectEvaluation';
import { ErrorBoundaryWrapper } from '../components/shared/ErrorBoundary/ErrorBoundary';
import { Redirect } from '../components/shared/Redirect/redirect';
import i18next from '../shared/translation/i18n';
import { isValidObject } from '../utils/filterTableData';
import { EvlProject } from '../utils/productEvluation';

import style from './sustainabilitySummaryPage.module.scss';

let root: Root | null = null;

export function mountProjectEvaluation(
  element: HTMLElement,
  projectName: Record<string, any>,
  reportData: Record<string, any>,
  backToProject: () => void,
  trackFn: (action: string) => any,
  downloadCSV?: any
): void {
  const defaultProps = {
    data: isValidObject(reportData) ? reportData : EvlProject,
    backToProject: backToProject,
    projectName: projectName,
    trackFn: trackFn,
    downloadCSV
  };

  if (!root) {
    root = createRoot(element);
  }

  root.render(<ProjectEvaluationPage {...defaultProps} />);
}
export function unmountProjectEvaluation(): void {
  if (root) {
    root.unmount();
    root = null;
  }
}

const ProjectEvaluationPage: React.FC<{
  data?: Record<string, any>;
  backToProject?: any;
  projectName?: Record<string, any>;
  trackFn?: any;
  downloadCSV?: any;
}> = ({ data, backToProject, projectName, trackFn, downloadCSV }) => {
  const pdfExportComponent = React.useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      unmountProjectEvaluation();
    },
    []
  );

  return (
    <div className={style.sustainabilitySummaryPageContainer}>
      <div ref={pdfExportComponent}>
        <I18nextProvider i18n={i18next}>
          <Redirect specifier={'project evaluation'} trackFn={trackFn} />
          <ErrorBoundaryWrapper>
            <ProjectEvaluation
              data={data}
              pdfRef={pdfExportComponent}
              backToProject={backToProject}
              projectName={projectName}
              trackFn={trackFn}
              downloadCSV={downloadCSV}
            />
          </ErrorBoundaryWrapper>
        </I18nextProvider>
      </div>
    </div>
  );
};

export default ProjectEvaluationPage;
