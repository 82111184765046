/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import { AISummary } from '../components/AISummary/Index';
import { ErrorBoundaryWrapper } from '../components/shared/ErrorBoundary/ErrorBoundary';
import i18next from '../shared/translation/i18n';

import style from './sustainabilitySummaryPage.module.scss';

let root: Root | null = null;

export function mountAISummary(element: HTMLElement, data?: any): void {
  if (!root) {
    root = createRoot(element);
  }

  root.render(<AISummaryPage data={data} />);
}
export function unmountAISummary(): void {
  if (root) {
    root.unmount();
    root = null;
  }
}

const AISummaryPage: React.FC<{ data?: any }> = ({ data }) => {
  const pdfExportComponent = React.useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      unmountAISummary();
    },
    []
  );

  return (
    <div className={style.sustainabilitySummaryPageContainer}>
      <div ref={pdfExportComponent}>
        <I18nextProvider i18n={i18next}>
          <ErrorBoundaryWrapper>
            <AISummary data={data} />
          </ErrorBoundaryWrapper>
        </I18nextProvider>
      </div>
    </div>
  );
};

export default AISummaryPage;
