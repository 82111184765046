import React from 'react';

import CustomButton from '../../../shared/CustomButton';

import style from '../../aiSearch.module.scss';
interface ModalFooterProps {
  closeModal?: () => void;
}
const ModalFooter: React.FC<ModalFooterProps> = ({ closeModal }) => (
  <div className={style.modalFooterContainer}>
    <div className={style.modalFooterButton} onClick={closeModal}>
      <CustomButton label={'Close'} className={style.modalFooterButtonStyle} />
    </div>
  </div>
);

export default ModalFooter;
